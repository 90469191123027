// @ts-nocheck
// eslint-disable
import Vue, { ref } from 'vue'
import VueRouter from 'vue-router'

import { i18nInstance } from '@/main'
import store from '@/store'
import dayjsClient from '@/v1.5/lib/dayjs'
import { loadLocaleMessages, switchI18nLanguage } from '@/v1.5/lib/i18n'
import { PosthogClient } from '@/v1.5/lib/posthog'
import routeStore from '@/v1.5/store/route/route.store'
import { I18N_LOCAL_STORAGE_KEY, ROUTES } from '@/v1.5/utils/config/constants'
import AdminDashboard from '@/views/admin/dashboard/Dashboard.vue'
import AdminGroups from '@/views/admin/groups/Groups.vue'
import AdminMentees from '@/views/admin/mentees/Mentees.vue'
import AdminMentors from '@/views/admin/mentors/Mentors.vue'
import AdminSessions from '@/views/admin/sessions/Sessions.vue'
import AdminUsers from '@/views/admin/users/Users.vue'
import chat from '@/views/discussion/chat.vue'
import DiscussionIndex from '@/views/discussion/index.vue'
import Visio from '@/views/discussion/Visio.vue'
import DownloadDelivrable from '@/views/DownloadDelivrable.vue'
import EmailMentorVisibility from '@/views/EmailMentorVisibility.vue'
import ForgotPassword from '@/views/forgotPassword.vue'
import Maintenance from '@/views/Maintenance.vue'
// import MatchBySpecialization from '@/views/onboarding/matching/MatchBySpecialization.vue'
import FillMatchInformations from '@/views/matching/FillMatchInformations.vue'
import MatchingLoading from '@/views/MatchingLoading.vue'
// import AddSpecialization from '@/views/onboarding/mentor/AddSpecialization.vue'
import IndexMentoringGroup from '@/views/mentoringGroup/index.vue'
import MentorList from '@/views/MentorList.vue'
// import RegisterBranch from '@/views/onboarding/RegisterBranch.vue'
import confirmAccountInformations from '@/views/onboarding/confirmAccountInformations.vue'
import EmailLogin from '@/views/onboarding/EmailLogin.vue'
import Login from '@/views/onboarding/login.vue'
import Register from '@/views/onboarding/Register.vue'
import RegisterRole from '@/views/onboarding/RegisterRole.vue'
import IndexUser from '@/views/profile/index.vue'
import IndexProfile from '@/views/profile/newIndex.vue'
import SessionIndex from '@/views/session/index.vue'
import SessionNew from '@/views/session/SessionNew.vue'
import Settings from '@/views/settings/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: '/email-login',
    name: 'EmailLogin',
    component: EmailLogin,
    meta: {
      public: true,
    },
  },
  {
    path: '/inscription',
    name: 'Register',
    component: Register,
    meta: {
      public: true,
    },
  },
  {
    path: '/mot-de-passe-oublie/:token?',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      public: true,
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
  },
  {
    path: '/matching/loading',
    name: 'MatchingLoading',
    component: MatchingLoading,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  // {
  //   path: '/inscription/filiere',
  //   name: 'RegisterBranch',
  //   component: RegisterBranch,
  //   meta: {
  //     requiresAuth: true,
  //     rejectAdmin: true,
  //     rejectMentor: true,
  //   },
  // },
  {
    path: '/inscription/role',
    name: 'RegisterRole',
    component: RegisterRole,
    meta: {
      requiresAuth: true,
      rejectAdmin: true,
      rejectMentor: true,
      rejectMentee: true,
    },
  },
  {
    path: '/inscription/confirm-informations',
    name: 'confirmAccountInformations',
    component: confirmAccountInformations,
    meta: {
      requiresAuth: true,
      rejectAdmin: true,
      rejectMentor: true,
      rejectMentee: true,
    },
  },
  // {
  //   path: '/inscription/etudiant/parcours/matching',
  //   name: 'MatchBySpecialization',
  //   component: MatchBySpecialization,
  //   meta: {
  //     requiresAuth: true,
  //     rejectGuest: true,
  //     rejectAdmin: true,
  //   },
  // },
  // {
  //   path: '/inscription/mentor/specialization',
  //   name: 'AddSpecialization',
  //   component: AddSpecialization,
  //   meta: {
  //     requiresAuth: true,
  //     rejectGuest: true,
  //     rejectAdmin: true,
  //     rejectMentee: true,
  //   },
  // },
  {
    path: '/matching/fill-informations/:id',
    name: 'FillMatchInformations',
    component: FillMatchInformations,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: '/liste-des-mentors',
    name: 'MentorList',
    component: MentorList,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: '/messages/:roomId?',
    name: 'DiscussionIndex',
    component: DiscussionIndex,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: ROUTES.CHAT(':roomId?'),
    name: 'Chat',
    component: chat,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: '/meeting/:id',
    name: 'Visio',
    component: Visio,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: ROUTES.DASHBOARD,
    name: 'IndexMentoringGroup',
    component: IndexMentoringGroup,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: ROUTES.PROFILE,
    name: 'NewIndexProfile',
    component: IndexProfile,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
    },
  },
  {
    path: `${ROUTES.USER(':id')}`,
    name: 'IndexProfile',
    component: IndexUser,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.SETTINGS,
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
    },
  },
  {
    path: '/group/:groupId/session/new',
    name: 'SessionNew',
    component: SessionNew,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: ROUTES.SESSIONS,
    name: 'SessionIndex',
    component: SessionIndex,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: '/SAML',
    name: 'SAML',
    beforeEnter() {
      if (import.meta.env.VITE_ENV === 'development') {
        location.href = 'http://localhost:8086'
      } else {
        location.href = 'https://sp.constel-education.com/auth'
      }
    },
  },
  {
    path: '/mentor/disponibilite',
    name: 'EmailMentorVisibility',
    component: EmailMentorVisibility,
  },
  // Admin routes
  {
    path: '/admin/mentors',
    name: 'AdminMentors',
    component: AdminMentors,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: '/admin/mentees',
    name: 'AdminMentees',
    component: AdminMentees,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.ADMIN.SESSIONS,
    name: 'AdminSessions',
    component: AdminSessions,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.ADMIN.GROUPS,
    name: 'AdminGroups',
    component: AdminGroups,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.ADMIN.DASHBOARD,
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.ADMIN.USERS,
    name: 'AdminUsers',
    component: AdminUsers,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: '/admin/session/:sessionId/user/:userId/deliverable/:deliverableId',
    name: 'DownloadDelivrable',
    component: DownloadDelivrable,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export const currentRouteRef = ref(router.currentRoute)

router.beforeEach(async (to, from, next) => {
  routeStore.setCurrentRoute(to)

  // get local from local storage
  let paramsLocal = window.localStorage.getItem(I18N_LOCAL_STORAGE_KEY)
  if (!paramsLocal) {
    paramsLocal = navigator.language.split('-')[0]
  }

  // load the messages
  await loadLocaleMessages(i18nInstance, paramsLocal)

  // if the locale is different from the current locale, set the new locale
  if (paramsLocal !== i18nInstance.locale) {
    switchI18nLanguage(i18nInstance, paramsLocal)
    dayjsClient.locale(paramsLocal)
  }

  // close and clean modals
  // used if the user try to click in a inner link inside a modal (force closing modals)
  store.dispatch('switchModal', { name: null, show: false })
  store.dispatch('setSelectedSessionId', null)

  // clarity tracking every page
  if (window.clarity && store.getters.authUserId) {
    window.clarity('identify', store.getters.authUserId, store.getters.authUserId, to, store.getters.authUserId)
  }

  if (to.name !== 'Maintenance' && store.getters.maintenance) {
    return next({ name: 'Maintenance' })
  }
  if (to.matched.some((record) => record.meta.rejectGuest)) {
    if (roleIsGuest()) {
      return next({ name: 'confirmAccountInformations' })
    }
  }
  if (to.matched.some((record) => record.meta.rejectMentor)) {
    if (roleIsMentor())
      return next({
        name: 'IndexMentoringGroup',
      })
  }
  if (to.matched.some((record) => record.meta.rejectMentee)) {
    if (roleIsMentee())
      return next({
        name: 'IndexMentoringGroup',
      })
  }
  if (to.matched.some((record) => record.meta.rejectAdmin)) {
    if (roleIsAdmin()) return next({ name: 'AdminDashboard' })
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if (!store.getters.isAuthenticated && store.getters.authSaml)
    //   return next({ name: 'SAML' })
    if (!store.getters.isAuthenticated || !store.getters.authUserRole) {
      const loginpath = window.location.pathname // enregistre la page de provenance
      next({ name: 'Login', query: { from: loginpath } })
    }
  }
  if (to.matched.some((record) => record.meta.public)) {
    // if (!store.getters.isAuthenticated && store.getters.authSaml)
    //   next({ name: 'SAML' })
    if (store.getters.isAuthenticated && store.getters.authUserRole) {
      if (roleIsMentor() || roleIsMentee())
        return next({
          name: 'IndexMentoringGroup',
        })
      else if (roleIsAdmin()) return next({ name: 'AdminDashboard' })
      else if (roleIsGuest()) return next({ name: 'confirmAccountInformations' })
    }
  }
  // 404
  if (!to.matched.length) {
    if (store.getters.isAuthenticated && store.getters.authUserRole) {
      if (roleIsMentor() || roleIsMentee())
        return next({
          name: 'IndexMentoringGroup',
        })
      else if (roleIsAdmin()) return next({ name: 'AdminDashboard' })
      else if (roleIsGuest()) return next({ name: 'confirmAccountInformations' })
    }
    // else if (!store.getters.isAuthenticated && store.getters.authSaml) {
    //   return next({ name: 'SAML' })
    // }
    else {
      return next({ name: 'Login' })
    }
  }

  next()
})

// send pageview to posthog
router.afterEach(() => {
  PosthogClient.capture('$pageview')
})

const roleIsMentor = () => {
  return store.getters.authUserRole === 'ROLE_MENTOR'
}
const roleIsMentee = () => {
  return store.getters.authUserRole === 'ROLE_STUDENT'
}
const roleIsGuest = () => {
  return store.getters.authUserRole === 'ROLE_GUEST'
}
const roleIsAdmin = () => {
  return store.getters.authUserRole === 'ROLE_ADMIN' || store.getters.authUserRole === 'ROLE_MANAGER'
}

export default router
