<template>
  <ModalStep
    id="session-details-modal-content"
    :onExitHandler="onExitHandler"
    :header="{
      title: t('common.session.capitalize'),
    }"
  >
    <template #headerAfterElement>
      <StatusBadge v-if="sessionRef?.status" :status="sessionRef?.status" />
    </template>
    <template #bodyContent>
      <component v-for="component in activeBodyComponentsRef" :key="component.name" :is="component" />
    </template>

    <template #footer v-if="activeFooterComponentRef">
      <component :is="activeFooterComponentRef" />
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/v1.5/features/auth/types'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import SessionDeliverables from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/components/session-deliverables/session-deliverables.vue'
import SessionGeneralInformations from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/components/session-general-informations/session-general-informations.vue'
import SessionParticipants from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/components/session-participants/session-participants.vue'
import SessionPendingActions from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/components/session-pending-actions/session-pending-actions.vue'
import SessionPresences from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/components/session-presences/session-presences.vue'
import SessionReport from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/components/session-report/session-report.vue'
import SessionReviews from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/components/session-reviews/session-reviews.vue'
import SessionDetailsPlannedFooter from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/footers/session-details-planned-footer.vue'
import SessionDetailsWaitConfirmationFooter from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/footers/session-details-wait-confirmation-footer.vue'
import SessionDetailsWaitReviewFooter from '@/v1.5/features/sessions/components/session-details-modal/session-details-modal-content/footers/session-details-wait-review-footer.vue'
import useSession from '@/v1.5/features/sessions/hooks/use-session.hook'
import { SessionStatusEnumType } from '@/v1.5/features/sessions/types'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import StatusBadge from '@/v1.5/features/ui/components/status-badge/status-badge.vue'
import { selectGetSessionDetailsModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import checkPermissionsGroup from '@/v1.5/features/users/utils'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

defineProps<{
  onExitHandler: (callback?: () => void) => void
}>()

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)
const { sessionRef } = useSession(sessionIdRef)

const { accountRef } = useAccount()

// active sidebar popup body component
// based on roles and session status
const activeBodyComponentsRef = computed(() => {
  // adview views
  if (checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.ADMIN)) {
    const sessionStatus = sessionRef.value?.status
    switch (true) {
      case sessionStatus === SessionStatusEnumType.FINISHED || sessionStatus === SessionStatusEnumType.ONGOING:
        return [
          SessionGeneralInformations,
          SessionParticipants,
          SessionReport,
          SessionPresences,
          SessionDeliverables,
          SessionReviews,
        ]

      default:
        return [SessionGeneralInformations, SessionParticipants]
    }
    // users views
  } else if (checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.USER)) {
    const sessionStatus = sessionRef.value?.status
    switch (true) {
      case sessionStatus === SessionStatusEnumType.WAIT_REVIEW || sessionStatus === SessionStatusEnumType.FINISHED:
        const views = [SessionGeneralInformations, SessionPendingActions, SessionParticipants]
        if (accountRef.value?.role === RoleEnumType.MENTOR) {
          views.push(SessionDeliverables)
        }
        if (sessionStatus === SessionStatusEnumType.FINISHED) {
        views.push(SessionReport)
        }
        return views

      default:
        return [SessionGeneralInformations, SessionParticipants]
    }
  }
  return []
})

// active sidebar popup footer component
// based on roles and session status
const activeFooterComponentRef = computed(() => {
  // admin views (they don't have footer actions atm)
  if (checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.ADMIN)) {
    return null

    // users views
  } else if (checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.USER)) {
    switch (sessionRef.value?.status) {
      case SessionStatusEnumType.PLANNED:
        return SessionDetailsPlannedFooter

      case SessionStatusEnumType.WAIT_CONFIRMATION:
        return SessionDetailsWaitConfirmationFooter

      case SessionStatusEnumType.WAIT_REVIEW:
        return SessionDetailsWaitReviewFooter

      default:
        return null
    }
  }
  return null
})
</script>

<style lang="scss">
@import './session-details-modal-content.scss';
</style>
