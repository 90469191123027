import { type CalendarOptions, GoogleCalendar, ICalendar, OutlookCalendar } from 'datebook'

import { getMeetingLink } from '@/v1.5/features/meetings/utils'
import { getDeliverableURL } from '@/v1.5/features/sessions/api'
import type { DeliverableType, V1SessionType, V2SessionType } from '@/v1.5/features/sessions/types'
import { SessionStatusEnumType } from '@/v1.5/features/sessions/types'
import dayjsClient from '@/v1.5/lib/dayjs'
import type { I18nTType, TranslationKeysType } from '@/v1.5/lib/i18n'
import type { ToastType } from '@/v1.5/types'
import { downloadFile } from '@/v1.5/utils/client/download-file'

export const sessionStatusClassNamesMap = {
  PLANNED: 'planned', // user accepted the session
  FINISHED: 'finished', // user finished the session
  WAIT_CONFIRMATION: 'wait-confirmation', // user has not accepted the session yet
  WAIT_REVIEWS: 'wait-review', // user needs to complete the session
  CANCELLED: 'cancelled', // user declined the session
} as Record<SessionStatusEnumType, string>

export enum SessionOptionEnumDefaultAllType {
  ALL = 'ALL',
}
export type SessionsStatusesOptionsType = SessionStatusEnumType | SessionOptionEnumDefaultAllType

export const sessionStatusesOptionsMap = {
  ALL: 'session.status.all',
  PLANNED: 'session.status.planned',
  WAIT_CONFIRMATION: 'session.status.waitConfirmation',
  WAIT_REVIEWS: 'session.status.waitReviews',
  FINISHED: 'session.status.finished',
  CANCELLED: 'session.status.cancelled',
  NONE: 'session.status.none',
  ONGOING: 'session.status.ongoing',
} as Record<SessionsStatusesOptionsType, TranslationKeysType>

export const sessionAdminStatusesOptionsMap = {
  ALL: 'session.status.admin.all',
  PLANNED: 'session.status.admin.planned',
  ONGOING: 'session.status.admin.ongoing',
  FINISHED: 'session.status.admin.finished',
  CANCELLED: 'session.status.admin.cancelled',
} as Record<SessionsStatusesOptionsType, TranslationKeysType>

export function getPlannedSessions(session: V2SessionType) {
  return session.status === SessionStatusEnumType.PLANNED
}

export function getWaitConfirmationSessions(session: V2SessionType) {
  return session.status === SessionStatusEnumType.WAIT_CONFIRMATION
}

export function getWaitReviewSessions(session: V2SessionType) {
  return session.status === SessionStatusEnumType.WAIT_REVIEW
}

export function getCompletedSessions(session: V2SessionType) {
  return session.status === SessionStatusEnumType.FINISHED
}

export function getCanceledSessions(session: V2SessionType) {
  return session.status === SessionStatusEnumType.CANCELLED
}

export function getUncompletedSessions(session: V2SessionType) {
  return (
    session.status === SessionStatusEnumType.WAIT_CONFIRMATION || session.status === SessionStatusEnumType.WAIT_REVIEW
  )
}

export function getAdminPlannedSessions(session: V1SessionType) {
  return session.statuses.some(
    (status) =>
      status.status === SessionStatusEnumType.PLANNED || status.status === SessionStatusEnumType.WAIT_CONFIRMATION,
  )
}

export function getAdminCompletedSessions(session: V1SessionType) {
  return session.statuses.every((status) => status.status === SessionStatusEnumType.FINISHED)
}

export function getAdminCanceledSessions(session: V1SessionType) {
  return session.statuses.every((status) => status.status === SessionStatusEnumType.CANCELLED)
}

export function sortSessionsByDate(sessionA: V2SessionType, sessionB: V2SessionType, order: 'asc' | 'desc' = 'asc') {
  if (order === 'asc') {
    return new Date(sessionA.from).getTime() - new Date(sessionB.from).getTime()
  }
  return new Date(sessionB.from).getTime() - new Date(sessionA.from).getTime()
}

// download deliverable
export async function downloadDeliverable({
  menteeId,
  deliverableId,
  sessionId,
  toast,
  t,
}: {
  menteeId: string
  deliverableId: string | undefined
  sessionId: string | undefined | null
  toast: ToastType | undefined
  t: I18nTType
}) {
  try {
    if (!deliverableId || !sessionId) {
      throw new Error('No deliverable id / session id found')
    }

    const data = await getDeliverableURL({
      userId: menteeId,
      sessionId,
      deliverableId,
    })
    const url = data.url
    downloadFile(url)
  } catch (e) {
    console.log(e)
    toast?.error(t('error.common'))
  }
}

// return deliverable by user id
export function returnDeliverableByUserId({
  menteeId,
  deliverables,
}: {
  menteeId: string
  deliverables: DeliverableType[] | undefined
}) {
  return (deliverables ?? []).find((deliverable) => {
    return deliverable.userId === menteeId
  })
}

export async function generateAddToCalendarOptions(session: V2SessionType, t: I18nTType) {
  const event: CalendarOptions = {
    start: new Date(session.from),
    end: new Date(session.to),
    title: `${t('session.addToCalendar.event.title')}`,
    location: session.location === 'visio' ? `${getMeetingLink(session)}` : session.location,
  }

  const icalendar = new ICalendar(event)
  const googleCalendar = new GoogleCalendar(event)
  const outlookCalendar = new OutlookCalendar(event)
  outlookCalendar.setHost('office')
  const ics = icalendar.render()
  const googleCalendarUrl = googleCalendar.render()
  const outlookCalendarUrl = outlookCalendar.render()

  const formattedDate = dayjsClient(new Date(session.from)).format('DD/MM/YYYY HH:mm')

  const filename = `${formattedDate}.ics`
  const file: Blob = await new Promise((resolve) => {
    resolve(new File([ics], filename, { type: 'text/calendar' }))
  })
  const icsUrl = URL.createObjectURL(file)

  return { icsUrl, googleCalendarUrl, outlookCalendarUrl }
}
