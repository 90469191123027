import { download, generateCsv, mkConfig } from 'export-to-csv'
import { utils as XLSXUtils, writeFileXLSX } from 'xlsx'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import router from '@/router'
import { createFileName, fetchAndPrepareSessionsForExport } from '@/v1.5/features/admin/sessions/utils'
import type { V2AccountDataType } from '@/v1.5/features/auth/types'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import logout from '@/v1.5/features/auth/utils/logout'
import { createConversationMutation } from '@/v1.5/features/chat/api'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import type { V2GroupType } from '@/v1.5/features/groups/types'
import { getMeetingLink } from '@/v1.5/features/meetings/utils'
import type { V2ProgramType } from '@/v1.5/features/programs/types'
import { V1UpdateSessionStatusMutation } from '@/v1.5/features/sessions/api'
import { SessionStatusEnumType, type V2SessionType } from '@/v1.5/features/sessions/types'
import { resetDb, runFixtures } from '@/v1.5/features/system/api'
import {
  resetMoreActionsStoreAction,
  setAttachedDataAction,
} from '@/v1.5/features/ui/components/more-actions-menu/store/more-actions.actions'
import type {
  AddToCalendarModalType,
  AdminGroupStructureModalType,
  EditSessionModalType,
  GroupSettingsModalType,
  SessionDetailsModalType,
  UserProfileModalType,
} from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import type { V2UserDataType } from '@/v1.5/features/users/types'
import type { I18nTType, TranslationKeysType } from '@/v1.5/lib/i18n'
import subscribeToStore from '@/v1.5/lib/zustand'
import type { ToastType } from '@/v1.5/types'
import { ROUTES } from '@/v1.5/utils/config/constants'
import { invalidateConversations, invalidateSession, invalidateSessions } from '@/v1.5/utils/lib/vue-query'

// Define a generic action type
export type ActionType = {
  fn: ({
    attachedData,
    toast,
    t,
  }: {
    attachedData: MoreActionsStoreType['attachedData']
    toast: ToastType
    t: I18nTType
  }) => void
  label: TranslationKeysType
  icon:
    | 'logout'
    | 'settings'
    | 'show'
    | 'copy'
    | 'edit'
    | 'cancel'
    | 'conversation'
    | 'download'
    | 'add-file'
    | 'email'
    | 'change'
    | 'calendar'
  isDanger?: boolean
  confirmLabel?: string
  confirmContent?: string
  restrictions?: {
    roles?: RoleEnumType[]
    sessionsStatus?: SessionStatusEnumType[]
  }
}
// Define a generic CommonMoreActionsType
type CommonMoreActionsType<T extends string, P> = {
  name: T
  actions: ActionType[]
  attachedData: Partial<P>
}

// Use specific action types for different contexts
type AccountMoreActionsType = CommonMoreActionsType<'account', V2AccountDataType>
type SessionCardMoreActionsType = CommonMoreActionsType<'session-card', V2SessionType>
type UserCardMoreActionsType = CommonMoreActionsType<'user-card', V2UserDataType>
export type AdminSessionsExportMoreActionsType = CommonMoreActionsType<
  'admin-sessions-export',
  { program: V2ProgramType; periodId: string; isLoading: boolean }
>
type AdminUsersImportMoreActionsType = CommonMoreActionsType<'admin-users-import', object>

type SystemMoreActionsType = CommonMoreActionsType<'system', object>

type UserRowMoreActionsType = CommonMoreActionsType<'user-row', V2UserDataType>

type AdminGroupActionsType = CommonMoreActionsType<'admin-group', V2GroupType>

export type MoreActionsStoreType =
  | AccountMoreActionsType
  | SessionCardMoreActionsType
  | UserCardMoreActionsType
  | AdminSessionsExportMoreActionsType
  | AdminUsersImportMoreActionsType
  | SystemMoreActionsType
  | UserRowMoreActionsType
  | AdminGroupActionsType

const accountActionItem = {
  name: 'account',
  actions: [
    {
      fn: function () {
        router.push(ROUTES.PROFILE).catch(() => {})
      },
      label: 'moreActions.account.seeProfile',
      icon: 'show',
    },
    {
      fn: function () {
        // set attached data for confirm action modal
        return logout()
      },
      label: 'moreActions.account.logout',
      confirmLabel: 'moreActions.account.logout.confirmLabel',
      icon: 'logout',
      isDanger: true,
    },
  ],
  attachedData: {},
} satisfies AccountMoreActionsType

const sessionCardActionItem = {
  name: 'session-card',
  actions: [
    {
      fn: ({ attachedData }) => {
        const session = attachedData as SessionCardMoreActionsType['attachedData']

        modalsStore.toggleModal('session-details', true)
        modalsStore.updateModalAttachedData<SessionDetailsModalType>('session-details', { sessionId: session.id })
      },
      label: 'moreActions.session.seeDetails',
      icon: 'show',
    },
    {
      fn: ({ attachedData }) => {
        const session = attachedData as SessionCardMoreActionsType['attachedData']

        modalsStore.toggleModal('edit-session', true)
        modalsStore.updateModalAttachedData<EditSessionModalType>('edit-session', { sessionId: session.id })
      },
      label: 'moreActions.session.modify',
      icon: 'edit',
      restrictions: {
        roles: [RoleEnumType.MENTOR],
        sessionsStatus: [SessionStatusEnumType.PLANNED],
      },
    },
    {
      fn: ({ attachedData, toast, t }) => {
        const session = attachedData as SessionCardMoreActionsType['attachedData']
        if (!session.id) return

        navigator.clipboard.writeText(getMeetingLink(session as V2SessionType))
        toast.success(t('moreActions.session.meetingLinkCopy.success'))
      },
      label: 'moreActions.session.meetingLinkCopy',
      icon: 'copy',
      restrictions: {
        roles: [RoleEnumType.MENTOR, RoleEnumType.MENTEE],
      },
    },
    {
      fn: ({ attachedData }) => {
        const session = attachedData as SessionCardMoreActionsType['attachedData']

        modalsStore.toggleModal('add-to-calendar', true)
        modalsStore.updateModalAttachedData<AddToCalendarModalType>('add-to-calendar', {
          session: session as V2SessionType,
        })
      },
      label: 'session.addToCalendar.session.title',
      icon: 'calendar',
      restrictions: {
        roles: [RoleEnumType.MENTOR, RoleEnumType.MENTEE],
      },
    },
    {
      fn: async ({ attachedData, toast, t }) => {
        const session = attachedData as SessionCardMoreActionsType['attachedData']

        await V1UpdateSessionStatusMutation({
          sessionId: session.id!,
          status: SessionStatusEnumType.CANCELLED,
        })

        await invalidateSessions({ groupId: groupsStore.selectedGroupId! })
        await invalidateSession(session.id!)
        toast.success(t('moreActions.session.cancel.success'))
      },

      label: 'moreActions.session.cancel',
      icon: 'cancel',
      isDanger: true,
      confirmLabel: 'moreActions.session.cancel.confirmLabel',
      confirmContent: `ui.modal.confirmation.irreversible`,
      restrictions: {
        roles: [RoleEnumType.MENTOR],
      },
    },
  ],
  attachedData: {},
} satisfies SessionCardMoreActionsType

const userCardActionItem = {
  name: 'user-card',
  actions: [
    {
      fn: ({ attachedData }) => {
        const user = attachedData as UserCardMoreActionsType['attachedData']
        modalsStore.toggleModal('user-profile', true)
        modalsStore.updateModalAttachedData<UserProfileModalType>('user-profile', { userId: user.id! })
      },
      label: 'moreActions.user.seeProfile',
      icon: 'show',
    },
    {
      fn: async ({ attachedData }) => {
        const user = attachedData as UserCardMoreActionsType['attachedData']

        const newConversation = await createConversationMutation({ sendAt: user.id! })
        await invalidateConversations(groupsStore.selectedGroupId!)
        router.push(ROUTES.CHAT(newConversation.conversationId)).catch(() => {})
      },
      label: 'moreActions.user.sendMessage',
      icon: 'conversation',
      restrictions: {
        roles: [RoleEnumType.MENTOR, RoleEnumType.MENTEE],
      },
    },
  ],
  attachedData: {},
} satisfies UserCardMoreActionsType

const adminSessionExportActionItem = {
  name: 'admin-sessions-export',
  actions: [
    {
      fn: async ({ attachedData, toast, t }) => {
        const { program, periodId } = attachedData as AdminSessionsExportMoreActionsType['attachedData']

        try {
          moreActionsStore.setAttachedData('admin-sessions-export', { isLoading: true })
          const filename = createFileName({
            programName: program?.name,
          })
          const csvConfig = mkConfig({
            useKeysAsHeaders: true,
            filename,
          })
          const sessions = await fetchAndPrepareSessionsForExport({
            programId: program?.id,
            periodId,
          })
          const csv = generateCsv(csvConfig)(sessions)
          download(csvConfig)(csv)
        } catch (error) {
          console.error('Error exporting sessions', error)
          toast?.error(t('moreActions.admin.sessions.export.error'))
        } finally {
          moreActionsStore.setAttachedData('admin-sessions-export', { isLoading: false })
        }
      },
      label: 'moreActions.admin.sessions.CSVExport',
      icon: 'download',
      restrictions: {
        roles: [RoleEnumType.ADMIN, RoleEnumType.MANAGER],
      },
    },
    {
      fn: async ({ attachedData, toast, t }) => {
        const { program, periodId } = attachedData as AdminSessionsExportMoreActionsType['attachedData']

        try {
          moreActionsStore.setAttachedData('admin-sessions-export', { isLoading: true })

          const filename = createFileName({
            programName: program?.name,
          })
          const sessions = await fetchAndPrepareSessionsForExport({
            programId: program?.id,
            periodId,
          })

          /* generate worksheet from state */
          const ws = XLSXUtils.json_to_sheet(sessions)

          /* create workbook and append worksheet */
          const wb = XLSXUtils.book_new()
          XLSXUtils.book_append_sheet(wb, ws, 'Data')

          /* export to XLSX */
          writeFileXLSX(wb, `${filename}.xlsx`)
        } catch (error) {
          console.error('Error exporting sessions', error)
          toast?.error(t('moreActions.admin.sessions.export.error'))
        } finally {
          moreActionsStore.setAttachedData('admin-sessions-export', { isLoading: false })
        }
      },
      label: 'moreActions.admin.sessions.XLSXExport',
      icon: 'download',
      restrictions: {
        roles: [RoleEnumType.ADMIN, RoleEnumType.MANAGER],
      },
    },
  ],
  attachedData: { isLoading: false },
} satisfies AdminSessionsExportMoreActionsType

const adminUsersImportActionItem = {
  name: 'admin-users-import',
  actions: [
    {
      fn: ({}) => {
        modalsStore.toggleModal('admin-users-import', true)
      },
      label: 'moreActions.admin.users.importFromExcel',
      icon: 'add-file',
      restrictions: {
        roles: [RoleEnumType.ADMIN, RoleEnumType.MANAGER],
      },
    },
    {
      fn: ({}) => {
        modalsStore.toggleModal('admin-manual-user-import', true)
      },
      label: 'moreActions.admin.users.emailInvitation',
      icon: 'email',
      restrictions: {
        roles: [RoleEnumType.ADMIN, RoleEnumType.MANAGER],
      },
    },
  ],
  attachedData: { isLoading: false },
} satisfies AdminUsersImportMoreActionsType

const systemActionItem = {
  name: 'system',
  actions: [
    {
      fn: async ({ toast, t }) => {
        try {
          toast.success(t('moreActions.system.resetDB.inProgress'))
          await resetDb()
          toast.success(t('moreActions.system.resetDB.success'))
        } catch (error) {
          toast.error(t('error.common'))
          console.error('Error executing command', error)
        }
      },
      label: 'moreActions.system.resetDB.large',
      icon: 'change',
    },
    {
      fn: async ({ toast, t }) => {
        try {
          toast.success(t('moreActions.system.resetDB.inProgress'))
          await runFixtures()
          toast.success(t('moreActions.system.resetDB.success'))
        } catch (error) {
          toast.error(t('error.common'))
          console.error('Error executing command', error)
        }
      },
      label: 'moreActions.system.resetDB.small',
      icon: 'change',
    },
  ],
  attachedData: {},
} satisfies SystemMoreActionsType

const userRowActionItem = {
  name: 'user-row',
  actions: [
    {
      fn: ({ attachedData }) => {
        const user = attachedData as UserRowMoreActionsType['attachedData']

        modalsStore.toggleModal('user-profile', true)
        modalsStore.updateModalAttachedData<UserProfileModalType>('user-profile', { userId: user.id! })
      },
      label: 'moreActions.user.sendMessage',
      icon: 'show',
    },
  ],
  attachedData: {},
} satisfies UserRowMoreActionsType

const adminGroupActionItem = {
  name: 'admin-group',
  actions: [
    {
      fn: ({ attachedData }) => {
        const group = attachedData as AdminGroupActionsType['attachedData']

        modalsStore.toggleModal('admin-group-structure', true)
        modalsStore.updateModalAttachedData<AdminGroupStructureModalType>('admin-group-structure', {
          groupId: group.id!,
        })
      },
      label: 'moreActions.admin.groups.modifyStructure',
      icon: 'edit',
    },
    {
      fn: ({ attachedData }) => {
        const group = attachedData as AdminGroupActionsType['attachedData']

        modalsStore.toggleModal('group-settings', true)
        modalsStore.updateModalAttachedData<GroupSettingsModalType>('group-settings', {
          groupId: group.id!,
        })
      },
      label: 'moreActions.admin.groups.modifySettings',
      icon: 'edit',
    },
  ],
  attachedData: {},
} satisfies AdminGroupActionsType

export type MoreActionsStoreValuesType = {
  moreActions: Record<MoreActionsStoreType['name'], MoreActionsStoreType>
}

export const INITIAL_MORE_ACTIONS_STORE_VALUES: MoreActionsStoreValuesType = {
  moreActions: {
    'account': accountActionItem,
    'session-card': sessionCardActionItem,
    'user-card': userCardActionItem,
    'user-row': userRowActionItem,
    'admin-sessions-export': adminSessionExportActionItem,
    'admin-users-import': adminUsersImportActionItem,
    'admin-group': adminGroupActionItem,
    'system': systemActionItem,
  },
}

const moreActionsStore = subscribeToStore(
  create(
    combine(INITIAL_MORE_ACTIONS_STORE_VALUES, (set, get) => ({
      reset: () => resetMoreActionsStoreAction(set),
      setAttachedData: (name: MoreActionsStoreType['name'], attachedData: MoreActionsStoreType['attachedData']) =>
        setAttachedDataAction(get, set, name, attachedData),
    })),
  ),
)
export default moreActionsStore
